import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		let e2 = document.querySelector("[data-facility-id]");
		let facilityid = parseInt(e2.getAttribute('data-facility-id'), 10);
		console.log('facility id::' + facilityid);
		var req = {
			fields: this.resultFields,
			filter: {
				'facility': facilityid
			},
			sorting: 'code',
			max: 100
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},

		myEventHandler(e) {
			V.log(window.innerWidth);
		}
	}

};