<template>
    <a href="javascript:0;"
        data-toggle="popover"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="gethelpText">
        <i class="fas fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'info-help',
    	props: {
    		serviceName: String
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = 'Zusätzliche Informationen';

    			return titleText;
    		},
    		gethelpText: function() {
    			//console.log(this.pricelist);

    			var helpText;
    			console.log("this.servicename==>" + this.serviceName);
    			if (this.serviceName == 'Wäschepaket (bezogen) - inkl. Handtücher') {
    				helpText = '1 x Bettwäsche (Bett wird von uns bezogen), 1 Badetuch, 1 Duschtuch, 1 Geschirrtuch, 1 Badevorleger';
    			}
    			if (this.serviceName == 'Wäschepaket (nicht bezogen) - inkl. Hand') {
    				helpText = '1 x Bettwäsche (Bett muss selbst bezogen werden), 1 Badetuch, 1 Duschtuch, 1 Geschirrtuch, 1 Badevorleger';
    			}
    			if (this.serviceName == 'Bettwäsche (bezogen)') {
    				helpText = '1 x Bettwäsche (Bett wird von uns bezogen)';
    			}
    			if (this.serviceName == 'Bettwäsche (nicht bezogen)') {
    				helpText = '1 x Bettwäsche (Bett muss selbst bezogen werden)';
    			}
    			return helpText;
    		}
    	}
    };
</script>